/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { Component } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import logoIcon from "../images/logo-icon.svg";
import trackReferrer from "../utils/beaker";
import theme from "../ui/theme";

const FooterWrapper = styled.footer`
  background: #081a31;
  nav {
    img {
      margin-right: 15px;
    }
    a {
      color: #fff;
      display: inline-block;
      font-size: 13px;

      &:hover {
        color: #3ac0a4;
      }

      &:not(:last-of-type):after {
        color: #fff;
        content: "|";
        display: inline-block;
        margin: 0 15px;
      }
    }
  }
`;

const FooterContainer = styled.div`
  margin: 0 auto;
  padding: 53px 20px;
  text-align: center;

  nav {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .footer_copyright {
    color: #b0b0b0;
    display: flex;
    font-size: 12px;
    justify-content: center;
    a {
      color: #b0b0b0;
      margin: 0 15px;
    }
    span {
      margin: 0 15px;
    }

    @media all and (max-width: ${theme.breakpoints.md}) {
      flex-wrap: wrap;
      a {
        flex-basis: 100%;
        padding: 15px 0 5px;
      }
      span {
        flex-basis: 100%;
      }
    }
  }
`;

class Footer extends Component {
  componentDidMount() {
    trackReferrer();
  }

  render() {
    const { navigation } = this.props;

    return (
      <FooterWrapper>
        <FooterContainer>
          <nav>
            <img src={logoIcon} alt="Travel Nursing" />
            {navigation.map((item) => {
              let url;
              if (item.object === "category") {
                url = `/category/${item.object_slug}/`;
              } else if (item.object === "custom") {
                url = item.url;
              } else {
                url = `/${item.object_slug}/`;
              }
              return (
                <Link to={url} key={item.object_slug}>
                  {item.title}
                </Link>
              );
            })}
          </nav>
          <div className="footer_copyright">
            <span>
              Copyright © {new Date().getFullYear()}. All Rights Reserved
            </span>
            <Link to="/terms/">Terms of Use</Link>
            <Link to="/privacy/">Privacy Policy</Link>
            <a
              className="do-not-sell-my-info"
              target="_blank"
              rel="noopener noreferrer"
              href="https://privacyportal-eu.onetrust.com/webform/990e21c3-12b1-4a5d-a701-69b493ad763d/99768d13-611c-4324-8bf3-00296536d0a7"
            >
              Do Not Sell My Personal Information
            </a>
            <a role="button" href="#" className="ot-sdk-show-settings">
              Cookie Settings
            </a>
          </div>
        </FooterContainer>
      </FooterWrapper>
    );
  }
}

export default Footer;
