/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import Header from "./Header";
import Footer from "./Footer";
import Leaderboard from "./Leaderboard";
import RelatedArticles from "../components/RelatedArticles";
import "../styles/default.scss";
import theme from "../ui/theme";

const MainContainer = styled.main`
  background: #fff;
  margin: 0 auto 50px;
  max-width: 1000px;
  padding: 26px 80px 50px;
  min-height: 60vh;

  @media all and (max-width: 960px) {
    padding: 26px 20px 50px;
  }
  &.blog {
    background: none;
    padding: 26px 0 0;
    @media all and (max-width: ${theme.breakpoints.md}) {
      padding: 26px 20px 0;
    }
  }
  &.author {
    background: none;
    padding: 56px 0 0;
    @media all and (max-width: ${theme.breakpoints.md}) {
      padding: 36px 20px 0;
    }
  }
  &.post,
  &.page {
    max-width: 960px;
    @media all and (max-width: 960px) {
      padding: 26px 7vw 50px;
    }
    @media all and (max-width: 800px) {
      padding: 0 20px 50px;
    }
  }
  &.index,
  &.confirmation {
    max-width: none;
    padding: 0;
    margin: -71px 0 0;
    position: relative;
    z-index: 1;
  }
  &.email-lander,
  &.sign-up-full,
  &.sign-up-form,
  &.mobile {
    max-width: none;
    padding: 0;
  }
  &.sign-up-form {
    margin: 0;
    min-height: unset;
  }
  &.email-lander,
  &.sign-up-full,
  &.mobile {
    margin: -68px 0 0;
    @media all and (max-width: 1079px) {
      margin: -59px 0 0;
    }
  }
  &.wide {
    margin: 0;
    max-width: none;
  }
  &.search-results,
  &.sorry {
    background: transparent;
    padding: 0;
  }
  &.search-results {
    background: transparent;
    padding: 40px 20px 50px;
  }
  &.contact,
  &.about,
  &.not-found {
    padding: 0 0 50px;
  }
`;

const Layout = ({ children, templateName, headerStyle, relatedArticles }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        headerMenu: wordpressWpApiMenusMenusItems(slug: { eq: "header-menu" }) {
          items {
            title
            url
            object
            object_slug
            wordpress_children {
              title
              url
              object
              object_slug
              wordpress_children {
                title
                url
                object
                object_slug
              }
            }
          }
        }
        footerMenu: wordpressWpApiMenusMenusItems(slug: { eq: "footer-menu" }) {
          items {
            title
            url
            object_slug
            object
          }
        }
      }
    `}
    render={(data) => {
      return (
        <>
          {templateName !== "sign-up-form" && (
            <Header
              template={templateName}
              headerStyle={headerStyle}
              navigation={data.headerMenu.items}
              siteTitle={data.site.siteMetadata.title}
            />
          )}
          <MainContainer className={templateName}>{children}</MainContainer>
          {(templateName === "index" || templateName === "email-lander") && (
            <Leaderboard />
          )}
          {templateName === "post" && (
            <>
              {relatedArticles && (
                <RelatedArticles relatedArticles={relatedArticles} />
              )}
              <Leaderboard />
            </>
          )}
          {templateName === "page" && (
            <>
              <Leaderboard heading="Make all the right career moves!" />
              {relatedArticles && (
                <RelatedArticles
                  heading="Learn more about travel nursing"
                  relatedArticles={relatedArticles}
                />
              )}
            </>
          )}

          {(templateName === "blog" || templateName === "search-results") && (
            <Leaderboard
              heading="Work Less, Travel More, Earn More!"
              subheading={false}
            />
          )}

          {templateName === "author" && (
            <Leaderboard
              heading="Jumpstart Your Career!"
              subheading={
                <span>
                  Earn up to $2,300 a week at top-tier hospitals across the
                  country!
                </span>
              }
            />
          )}
          <Footer navigation={data.footerMenu.items} />
        </>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
